$color-white: #ffffff;

$color-gray-5: #f1f3f6;

$color-xmart: #db5867;
$color-xmart-seethrough: rgba(219, 88, 103, 0.1);

$color-pink: #ff008a;
$color-pink-seethrough: rgba(255, 0, 138, 0.1);

$color-purple: #2d2ace;
$color-purple-seethrough: rgba(45, 42, 206, 0.1);

$color-yellow: #fcb831;
$color-yellow-seethrough: rgba(252, 184, 49, 0.1);

$color-darkGrey: #666666;
$color-Grey: #707070;
$color-header: rgba(0, 0, 0, 0.65);

$color-green: #50ce89;
$color-green-seethrough: rgba(80, 206, 137, 0.1);
